import React from 'react';
import styled from '@emotion/styled';

const DividerContent = styled.div`
  margin: auto;
  width: 80px;
  border-top-width: 0.5rem;
  border-top-color: rgb(205, 205, 205);
  border-top-style: solid;
`;

const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 2rem 0;
`;

export const Divider = (props) => (
  <DividerContainer {...props}>
    <DividerContent />
  </DividerContainer>
);

export default Divider;
