import React, { Suspense } from 'react';
import { css } from '@emotion/core';
import { useText, useLanguage } from 'src/components/TextContext';
import { SectionHeader } from 'src/components/pages/index';
import { Divider } from 'src/components/Divider';
import { Typography } from '@material-ui/core';

const LazyTreesCoverflow = React.lazy(() => import('./TreesCoverflow'));

export const PlantaTuArbolContent = () => {
  const t = useText();
  if (typeof window == 'undefined') {
    return null;
  }
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}
    >
      <SectionHeader
        css={css`
          margin-top: 4rem;
        `}
      >
        {t.plantaTuArbol.title}
      </SectionHeader>
      <Divider />
      <Typography
        css={css`
          font-size: 18px;
          white-space: pre-wrap;
          margin: auto;
          text-align: justify;
          margin-bottom: 1rem;
          max-width: 70rem;
          padding: 0 1rem;
        `}
      >
        {t.plantaTuArbol.content}
      </Typography>
      <Suspense fallback={null}>
        <LazyTreesCoverflow />
      </Suspense>
    </div>
  );
};
export default PlantaTuArbolContent;
