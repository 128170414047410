import React from 'react';
import { css } from '@emotion/core';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { IndexHeader, IndexFooter } from 'src/components/pages/index';
import { PlantaTuArbolContent } from 'src/components/pages/planta-tu-arbol/PlantaTuArbolContent';
import { TextContextProvider } from 'src/components/TextContext';

import esTextCommon from '../../text/es/common.yaml';
import esTextPlantaTuArbol from '../../text/es/planta-tu-arbol.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextPlantaTuArbol from '../../text/en/planta-tu-arbol.yaml';

const esText = { ...esTextCommon, ...esTextPlantaTuArbol };
const enText = { ...enTextCommon, ...enTextPlantaTuArbol };
const text = {
  es: esText,
  en: enText,
};

const QuienesSomosPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text} initialLanguage={'es'}>
        <Layout>
          <IndexHeader backgroundVisibility={true} location={location} />
          <Main>
            <PlantaTuArbolContent />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default QuienesSomosPage;
